import { setClaimStep } from "@/step-handler"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import ClaimSteps from ".."

async function exitMarriageClaim() {
	App.CustomerClaim.MarriageClaimSkipped = true
	await setClaimStep(ClaimSteps.Estimate)
}

const MarriageClaimNotValid: ReactiveComponent = () => {
	return () => (
		<div class="col-12 my-1">
			<div class="alert bg-orange border">
				<h2 class="xbold">Sorry - you don't qualify for a claim</h2>
				<p class="text-center">Based on your answers, you're not likely to qualify for a marriage tax rebate.</p>
			</div>
			<div class="alert bg-grey">
				<h2 class="xbold">Don't go though!</h2>
				<p class="text-center text-light">You could still qualify for a rebate based on your job.</p>
				<button type="button" class="btn btn-success option mb-0" onClick={() => void exitMarriageClaim()}>
					YES, I'd like to find out <i class="fa fa-angle-double-right" />
				</button>
			</div>
		</div>
	)
}

export default defineComponent(MarriageClaimNotValid)
