import ButtonNavigation from "@/components/ButtonNavigation"
import RadioButton from "@/components/RadioButton"
import type { Id, WoreUniformTaxYear } from "@/types"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const tableStyles = css`
	width: 100%;

	td,
	th {
		padding: 0.5rem;
		vertical-align: middle;
	}

	thead th {
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
	}

	tbody tr {
		border-bottom: solid 2px #fff;
	}

	label {
		margin-right: 0.25rem;
	}
`

const UniformTaxYearsStep: ReactiveComponent = () => {
	const uniformTaxYears = computed(() => {
		const years = [...(App.CustomerClaim.WoreUniformTaxYears ?? [])]
		years.sort((y1, y2) => y2.TaxYearId - y1.TaxYearId)
		return years
	})

	function taxYearName(taxYearId: Id) {
		return App.MarriageTaxYears?.find((ty) => ty.TaxYearId === taxYearId)?.Description ?? ""
	}

	function renderYear(year: WoreUniformTaxYear) {
		return (
			<tr key={year.TaxYearId}>
				<td class="text-left">
					<span class="font-weight-bold">{`06/04/${taxYearName(year.TaxYearId).split("/")[0].substring(2)}`}</span>
					&nbsp;to&nbsp;
					<span class="font-weight-bold">{`05/04/${taxYearName(year.TaxYearId).split("/")[1]}`}</span>
				</td>
				<td class="text-center">
					<RadioButton
						name={`woreUniform${year.TaxYearId}`}
						enabledValue={true}
						value={year.WoreUniform}
						setValue={(v) => (year.WoreUniform = v)}
						required
					/>
				</td>
				<td class="text-center">
					<RadioButton
						name={`woreUniform${year.TaxYearId}`}
						enabledValue={false}
						value={year.WoreUniform}
						setValue={(v) => (year.WoreUniform = v)}
						required
					/>
				</td>
			</tr>
		)
	}

	return () => (
		<>
			<div class="col-12 my-2">
				<h3 class="bold pb-2">Wearing Uniform</h3>
				<p class="text-center my-3">In which of these tax years did you wear a uniform?</p>
				<table class={tableStyles}>
					<thead>
						<tr>
							<th style={{ width: "40%" }} />
							<th style={{ width: "20%" }}>Yes</th>
							<th style={{ width: "20%" }}>No</th>
						</tr>
					</thead>
					<tbody>{uniformTaxYears.value.map(renderYear)}</tbody>
				</table>
			</div>
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(UniformTaxYearsStep)
