import ButtonNavigation from "@/components/ButtonNavigation"
import { animateFigures } from "@/utils/animate"
import { scrollTo } from "@/utils/scrolling"
import { computed, onMounted } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import EditSignature from "../components/EditSignature"

function scrollToSignature() {
	if (App.CustomerClaim.MarriageData?.AgreeToTerms) {
		scrollTo("signatureAnchor")
	}
}

const MarriageSignatureStep: ReactiveComponent = () => {
	const termsEl = document.getElementById("calculator-terms-marriage") as HTMLTemplateElement
	const estimate = computed(() => {
		if (App.CustomerClaim.MarriageData) {
			const refund = 1002
			const subtract = App.CustomerClaim.MarriageData.ClaimedBeforeYears.filter((_) => _ !== null && parseInt(_) > 0).length * 250
			return refund - subtract
		} else {
			return 0
		}
	})
	onMounted(() => {
		animateFigures()
	})
	return () =>
		!!App.CustomerClaim.MarriageData && (
			<>
				<div class="col-12">
					<div class="text-center text-uppercase mb-2 mb-md-3 mx-auto">
						<div class="px-3 py-3 px-sm-4 py-sm-4 bg-orange text-light rounded">
							<span class="d-block xs-xsmall count-up-subtext">You COULD be owed</span>
							<span class="xbold count-up-lg">£</span>
							<span class="xbold countup count-up-lg" data-value={estimate.value}>
								-
							</span>
							<span class="xbold count-up-lg">*</span>
						</div>
					</div>
				</div>
				<div class="col-12">
					<div class="text-center">
						<div class="px-3 px-sm-4 py-2 py-sm-3 bg-light">
							<span class="small">
								Estimate is given on the basis of the information you have provided, assuming that you either you or you spouse can transfer
								between 1 and 4 years of your personal allowance at a tax rate of 20% or higher. This estimate is not intended to confirm that a
								refund is due and this can be only be determined if you and your spouse both agree to claim and submit the relevant details.
							</span>
						</div>
					</div>
				</div>
				<div class="col-12 my-2 text-center">
					<label class="custom-control custom-control-inline custom-checkbox">
						<input
							type="checkbox"
							class="custom-control-input required"
							v-model={App.CustomerClaim.MarriageData.AgreeToTerms}
							name="terms"
							id="inp_accept_terms"
							onChange={scrollToSignature}
							data-validation="You must read and agree to the terms & conditions before you can proceed."
						/>

						<span class="custom-control-label">
							I agree to Online Tax Rebate's{" "}
							<a href="#marriageTerms" class="bold">
								terms and conditions
							</a>
							.
						</span>
					</label>
				</div>
				<div class="col-12">
					<div class="text-center" style="max-height: 200px; overflow-y: scroll;" id="marriageTerms">
						<div class="px-3 px-sm-4 py-2 py-sm-3 bg-light">
							<h4 class="text-dark">Terms and Conditions</h4>
							<small innerHTML={termsEl.innerHTML} />
						</div>
					</div>
				</div>
				<span id="signatureAnchor">-</span>
				<EditSignature
					setSignatureSVG={(svg) => {
						if (App.CustomerClaim.MarriageData !== null) {
							App.CustomerClaim.MarriageData.SignatureSVG = svg
						}
					}}
					setSignaturePoints={(points) => {
						if (App.CustomerClaim.MarriageData !== null) {
							App.CustomerClaim.MarriageData.SignaturePoints = points
						}
					}}
				/>

				<ButtonNavigation nextText="Submit Marriage Claim" />
			</>
		)
}

export default defineComponent(MarriageSignatureStep)
