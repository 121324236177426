import ButtonNavigation from "@/components/ButtonNavigation"
import { useEmailDidYouMean } from "@/composition/useEmailDidYouMean"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import TrustPilotDisplay from "./components/TrustPilotDisplay"

const EmailStep: ReactiveComponent = () => {
	useEmailDidYouMean(
		App.CustomerClaim.ClaimId,
		() => App.CustomerClaim.Email,
		(newEmail) => {
			//Set both email & email confirm - assumption is at this point validation has passed they are the same
			App.CustomerClaim.Email = newEmail
			App.CustomerClaim.emailConfirm = newEmail
		}
	)

	return () => (
		<>
			<div class="col-12 mb-2 alert bg-green text-light text-center">
				We only need a couple more bits of information to give you your <br />
				<span class="bold">FREE ESTIMATE</span>
			</div>
			<div class="col-12 my-2 col-md-6">
				<input
					type="email"
					name="email_address"
					class="form-control required match"
					v-model={App.CustomerClaim.Email}
					placeholder="Your email address"
					data-validation="Please provide your email address"
					autocomplete="on"
					required
				/>
			</div>
			<div class="col-12 my-2 col-md-6">
				<input
					type="email"
					name="email_address_repeat"
					class="form-control required match"
					v-model={App.CustomerClaim.emailConfirm}
					placeholder="Please repeat email address"
					data-validation="Please repeat your email address"
					autocomplete="on"
					required
				/>
			</div>
			{!suppressUBOptin ? (
				<div class="col-12 my-2">
					<small style="line-height: 1em;">
						This FREE tax rebate estimate is provided by Online Tax Rebates, part of{" "}
						<a class="bold text-light" href="https://www.uniformbenefits.com" target="_blank">
							UniformBenefits.com
						</a>{" "}
						– a service dedicated to saving you time and money.
					</small>
					<label class="custom-control custom-control-inline custom-radio">
						<input
							type="radio"
							name="optin"
							v-model={App.CustomerClaim.ThirdPtyPermission}
							id="inp_optin_yes"
							value="true"
							class="custom-control-input required"
							data-validation="Please choose whether or not you'd like to receive your UniformBenefits.com bonus"
							required
						/>

						<span class="custom-control-label">
							<strong>YES – I'd like to find out how much tax rebate I'm owed.</strong> I consent to receive electronic communications from
							UniformBenefits.com about my rebate and to inform me of money-saving offers and services.{" "}
							<strong>I understand that I can opt-out of these communications at any time.</strong>
						</span>
					</label>
					<label class="custom-control custom-control-inline custom-radio">
						<input
							type="radio"
							name="optin"
							v-model={App.CustomerClaim.ThirdPtyPermission}
							id="inp_optin_no"
							value="false"
							class="custom-control-input required"
							required
						/>

						<span class="custom-control-label">
							No. I don't want to be told about money-saving offers and other services from UniformBenefits.com but I'd still like to see how
							much rebate I'm owed.
						</span>
					</label>
				</div>
			) : (
				<input type="hidden" value={false} v-model={App.CustomerClaim.ThirdPtyPermission} />
			)}

			<ButtonNavigation nextDisabled={App.CustomerClaim.ThirdPtyPermission === null} />
			<div class="col-12 my-2">
				<div class="jumbotron inverse">
					<TrustPilotDisplay />
				</div>
			</div>
		</>
	)
}

export default defineComponent(EmailStep)
