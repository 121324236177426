import { goToNextStep, goToPreviousStep } from "@/step-handler"
import type { ClaimStepData } from "@/steps"
import { defineComponent, optionalProp, type ReactiveComponent } from "vue-utils"

interface Props {
	backDisabled?: string | boolean
	nextDisabled?: string | boolean

	hideBack?: boolean
	hideNext?: boolean

	nextStep?: ClaimStepData
	previousStep?: ClaimStepData

	nextText?: string
	backText?: string
}

const ButtonNavigation: ReactiveComponent<Props> = (props) => {
	return () => (
		<>
			<div class="d-flex justify-content-center my-2 w-100" style={{ columnGap: "2rem" }}>
				{!props.hideBack && (
					<button
						type="button"
						class="btn btn-warning bg-orange back"
						title={typeof props.backDisabled === "string" ? props.backDisabled : ""}
						disabled={!!props.backDisabled}
						onClick={() => void goToPreviousStep(props.previousStep)}
					>
						{"<"} {props.backText ?? "Back"}
					</button>
				)}
				{!props.hideNext && (
					<button
						type="button"
						class="btn btn-success next"
						title={typeof props.nextDisabled === "string" ? props.nextDisabled : ""}
						disabled={!!props.nextDisabled}
						onClick={() => void goToNextStep(props.nextStep)}
					>
						{props.nextText ?? "Next"} {">"}
					</button>
				)}
			</div>

			<div class="position-fixed d-md-none step-footer-fixed">
				<div class="inner-container py-2">
					<div class="row align-items-center">
						<div class="col text-center">
							{!props.hideBack && (
								<button
									type="button"
									class="btn btn-warning bg-orange back"
									title={typeof props.backDisabled === "string" ? props.backDisabled : ""}
									disabled={!!props.backDisabled}
									onClick={() => void goToPreviousStep(props.previousStep)}
								>
									{"<"} {props.backText ?? "Back"}
								</button>
							)}
							{!props.hideNext && (
								<button
									type="button"
									class="btn btn-success next"
									title={typeof props.nextDisabled === "string" ? props.nextDisabled : ""}
									disabled={!!props.nextDisabled}
									onClick={() => void goToNextStep(props.nextStep)}
								>
									{props.nextText ?? "Next"} {">"}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default defineComponent(ButtonNavigation, {
	backDisabled: optionalProp(Boolean, String),
	nextDisabled: optionalProp(Boolean, String),
	hideBack: optionalProp(Boolean),
	hideNext: optionalProp(Boolean),
	previousStep: optionalProp(Object),
	nextStep: optionalProp(Object),
	nextText: optionalProp(String),
	backText: optionalProp(String),
})
