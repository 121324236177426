import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import SignatureDetails from "./SignatureDetails"

const SignatureStep: ReactiveComponent = () => {
	const termsEl = document.getElementById("calculator-terms-2") as HTMLTemplateElement

	return () => (
		<>
			<SignatureDetails />
			<ButtonNavigation backText="CANCEL" nextText="Get My Refund" />

			<div class="col-12 my-1 p-1 px-md-3">
				<div class="row">
					<div class="col-12 text-secondary">
						<small innerHTML={termsEl.innerHTML} />
					</div>
				</div>
			</div>
		</>
	)
}

export default defineComponent(SignatureStep)
