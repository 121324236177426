import ButtonNavigation from "@/components/ButtonNavigation"
import CurrencyInput from "@/components/CurrencyInput"
import Question from "@/components/Question"
import RadioButtons from "@/components/RadioButtons"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const IncomeStep: ReactiveComponent = () => {
	return () => (
		<>
			<Question question="Roughly, what is your annual salary (£)?">
				<div class="d-flex justify-content-center mt-2">
					<CurrencyInput
						class="form-control"
						value={App.CustomerClaim.RoughAnnualSalary}
						setValue={(salary) => (App.CustomerClaim.RoughAnnualSalary = salary)}
						step={100}
						placeholder="Enter your rough annual salary (£)"
						style={{ maxWidth: "20rem" }}
						required
					/>
				</div>
			</Question>
			<Question question="Has your income been at or above £12,500 for all of the past 4 years?">
				<RadioButtons
					name="low-earner"
					value={App.CustomerClaim.EarningsAboveMinimum}
					setValue={(value) => {
						App.CustomerClaim.EarningsAboveMinimum = value
						App.CustomerClaim.Employed = value
					}}
					required
					reverse
				/>
			</Question>

			<ButtonNavigation />
		</>
	)
}

export default defineComponent(IncomeStep)
