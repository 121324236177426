import type { ClaimCustomer, MODClaim } from "./types"

export const defaultCustomerClaim: ClaimCustomer = {
	UserId: "",
	ClaimId: 0,
	CustomerId: 0,
	DateStamp: null,
	ClaimStatus: 1,
	Title: "",
	Address1: "",
	Address2: "",
	Address3: "",
	County: "",
	Postcode: "",
	DateOfBirth: null,
	FirstName: "",
	Surname: "",
	Email: "",
	Phone: "",
	noPhone: false,
	IPAddress: IP,
	Married: null,
	Employed: null,
	SpouseEmployed: null,
	EarningsAboveMinimum: null,
	SpouseEarningsAboveMinimum: null,
	MarriageAllowanceTransfer: null,
	ClaimedUniformAllowanceCurrentJob: null,
	ClaimedUniformAllowancePreviousJob: null,
	NI: "",
	ThirdPtyPermission: null,
	TaxYearIds: [],
	AverageRefund: 0,
	MaxRefund: 0,
	AnnualRefund: 0,
	EstimatedClaim: 0,
	BrandId: brand.BrandId,
	AcceptedTerms: false,
	selfAssessed: null,
	emailConfirm: "",
	niConfirm: "",
	UniformBenefitsSignUpCompleted: false,
	SourceBrandId: brand.BrandId,
	SignatureSVG: "",
	SARSignatureSVG: "",
	AWC: awcvalue,
	EmploymentRecords: [],
	Employers: [
		{
			EmployerName: "",
			PAYEReference: "",
			Address: "",
			YearCommenced: null,
			Postcode: "",
			LookupDataSectorIdentifier: "",
			LookupDataSectorCategoryIndex: null,
			OccupationId: null,
			IndustryId: null,
			JobTitle: "",
		},
	],
	ConsentContactEmployers: null,
	Subscriptions: [],
	ClaimedBefore: null,
	OccupationDeduction: null,
	TaxYearStatuses: [],
	ClientPath: window.location.pathname,
	MarriageDate: "",
	MarriageData: null,
	WorkFromHomeYears: [],
	EarningsBand: null,
	EarningsBandSpouse: null,
	SACEarns100k: null,
	SAC10kInvestmentsIncome: null,
	SACEarns50kPlusBens: null,
	SACFilesTaxReturn: null,
	SACHasAccountant: null,
	SACHasBusiness: null,
	SACHasProperty: null,
	SACIncomeFromOtherCountry: null,
	SACIsDirector: null,
	SACIsSelfEmployed: null,
	SourceData: sourcedata,
	TrustPilotSiteUrl: "",
	IsRepeatCustomer: false,
	OtherExpenses: 0,
	MarriageClaimTaxYears: null,
	MarriageClaimSkipped: false,
	QualifiesForMarriageClaim: null,
	SampleOccupationRefunds: [],
	WoreUniformTaxYears: [],
	RoughAnnualSalary: null,
}

export const defaultMODClaim: MODClaim = {
	ID: 0,
	CompletedPhaseOneTraining: null,
	PostedToBaseTwoYearsOrLess: null,
	UseOwnVehicle: null,
	Title: "na",
	FirstName: "",
	Surname: "",
	MobileNumber: "",
	EmailAddress: "",
	Postcode: "",
	Address1: "",
	Address2: "",
	Address3: "",
	County: "",
	AdditionalInfo: "",
	CustomerId: 0,
	ClaimId: 0,
	BrandId: 1,
}
