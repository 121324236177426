import type { MarriageGender } from "@/types/MarriagePartnerShipClaimDetail"
import { defineComponent, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"

interface Props {
	gender: MarriageGender | null
	setGender(gender: MarriageGender): void
}

const SelectGender: ReactiveComponent<Props> = (props) => {
	return () => (
		<select class="form-control" value={props.gender ?? ""} onInput={useOnInput((value) => props.setGender(value as MarriageGender))} required>
			<option value="" disabled>
				Select gender
			</option>
			<option value="m">Male</option>
			<option value="f">Female</option>
			<option value="o">Other/Prefer not to say</option>
		</select>
	)
}

export default defineComponent(SelectGender, {
	gender: requiredProp(String, null),
	setGender: requiredProp(Function),
})
