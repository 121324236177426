import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const ComplianceStatement: ReactiveComponent = () => {
	const managedContent = document.getElementById("calculator-compliance") as HTMLTemplateElement
	return () => (
		<>
			<div class="row">
				<div class="col col-md-6 mb-3 mx-auto text-center" style={{ maxHeight: "250px", overflowY: "auto", fontSize: "0.9rem" }}>
					<div innerHTML={managedContent.innerHTML} />
				</div>
			</div>

			<ButtonNavigation nextText="Accept" />
		</>
	)
}

export default defineComponent(ComplianceStatement)
