import { onMounted, onUnmounted, unref, type Ref } from "vue"

const checks = new Map<symbol, () => void>()
export const useInputValidation = <T extends { setCustomValidity(message: string): void } & EventTarget>(
	element: Ref<T | undefined> | T,
	getError: (element: T) => string | null | true
) => {
	const symbol = Symbol()

	function checkValidity() {
		const elementValue = unref(element)
		if (elementValue) {
			const rawResult = getError(elementValue)
			const errorMessage = rawResult === null || rawResult === true ? "" : rawResult.trim()

			elementValue.setCustomValidity(errorMessage)
		}
	}

	onMounted(() => {
		checks.set(symbol, checkValidity)
	})

	onUnmounted(() => {
		checks.delete(symbol)
	})
}

export const runCustomInputValidation = () => {
	for (const elementCheck of checks.values()) {
		elementCheck()
	}
}
