import ButtonNavigation from "@/components/ButtonNavigation"
import Question from "@/components/Question"
import RadioButtons from "@/components/RadioButtons"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const MarriageClaimedBeforeStep: ReactiveComponent = () => {
	return () =>
		!!App.CustomerClaim.MarriageData && (
			<>
				<div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 mx-auto mb-4">
					<div class="my-3 claim-label bold">Claimed Before?</div>
					<Question question="Have you claimed the marriage tax rebate before?">
						<RadioButtons
							name="marriage-claimed-before"
							value={App.CustomerClaim.MarriageData.ClaimedBefore === "yes"}
							setValue={(value) => {
								if (App.CustomerClaim.MarriageData !== null) {
									App.CustomerClaim.MarriageData.ClaimedBefore = value ? "yes" : "no"
								}
							}}
							data-validation="Please tell us if you have claimed the marriage tax rebate before"
							required
						/>
					</Question>
					{App.CustomerClaim.MarriageData.ClaimedBefore === "yes" && (
						<div class="col-12">
							<div>For which previous years have you already claimed the marriage tax transfer?</div>
							{App.CustomerClaim.MarriageData.MarriageClaimTaxYears.slice(0, 4).map((taxYear, j) => (
								<label
									class={
										App.CustomerClaim.MarriageData?.ClaimedBeforeYears[j] === taxYear.Year.toString()
											? "mb-1 mx-1 py-1 px-4 fancy-checkbox selected"
											: "mb-1 mx-1 py-1 px-4 fancy-checkbox"
									}
									key={taxYear.Year}
								>
									<input
										type="checkbox"
										name="ClaimedBeforeYears"
										value={taxYear.Year}
										v-model={App.CustomerClaim.MarriageData!.ClaimedBeforeYears}
									/>
									<span class="px-3">{taxYear.Year}</span>
								</label>
							))}
						</div>
					)}
				</div>

				<ButtonNavigation />
			</>
		)
}

export default defineComponent(MarriageClaimedBeforeStep)
