import ButtonNavigation from "@/components/ButtonNavigation"
import { goToNextStep } from "@/step-handler"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import PostcodeInput from "./components/PostcodeInput"
import ResumeClaim from "./components/ResumeClaim"

const PostcodeStep: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col col-md-10 col-lg-8 mx-auto">
				<div class="row">
					<div class="col-12">
						<div class="row">
							<div class="col-12 my-2 text-center">
								<h3 class="bold">Your Home postcode</h3>
							</div>
							<div class="col-10 col-lg-8 mx-auto text-center">
								<PostcodeInput
									class="text-center bold my-auto"
									postcode={App.CustomerClaim.Postcode}
									setPostcode={(postcode) => (App.CustomerClaim.Postcode = postcode)}
									onKeyup={(e) => {
										if (e.key === "Enter") {
											void goToNextStep()
										}
									}}
								/>
								<small>We'll confirm your full address later.</small>
							</div>
						</div>
					</div>
					<div class="col-12 align-middle mt-2">
						{App.IsQuickEstimate && App.InitialLoadWithExistingClaim ? <ResumeClaim /> : <ButtonNavigation />}
					</div>
				</div>
			</div>
		</>
	)
}

export default defineComponent(PostcodeStep)
