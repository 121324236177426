import { defineGlobals } from "vue-utils"
import { ClaimType, type ClaimStepData } from "./types"

export * from "./types"

const step = (step: number, name: string, type: ClaimType = ClaimType.Standard, extra: Partial<ClaimStepData> = {}): ClaimStepData => ({
	step,
	name,
	type,
	isIssue: false,
	...extra,
})

const issueStep = (step: number, name: string, type: ClaimType = ClaimType.Standard, extra: Partial<ClaimStepData> = {}): ClaimStepData => ({
	step,
	name,
	type,
	isIssue: true,
	getPreviousStep: () => null,
	getNextStep: () => null,
	shouldSkipStep: () => true,
	...extra,
})

const Welcome = step(1, "welcome")
const ComplianceStatement = step(2, "compliance-statement")
const Employer = step(3, "employer")
const Occupations = step(4, "occupations")
const Subscriptions = step(5, "subscriptions", ClaimType.Standard, {
	shouldSkipStep: (claim) => claim.Subscriptions.length === 0,
})
const SubscriptionDetails = step(6, "subscription-details", ClaimType.Standard, {
	shouldSkipStep: (claim) => claim.Subscriptions.filter((sub) => sub.Enabled).length === 0,
})
const Postcode = step(7, "postcode")
const SelfAssessedStep = step(8, "self-assessed")
const SelfAssessedChecks = step(9, "self-assessed-checks", ClaimType.Standard, {
	shouldSkipStep: (claim) => claim.selfAssessed === true || claim.selfAssessed === false,
})
const AreYouMarried = step(10, "are-you-married", ClaimType.Standard, {
	shouldSkipStep: (claim) => claim.MarriageClaimSkipped === true,
})
const PandemicWorkFromHome = step(11, "pandemic-work-from-home")
const Income = step(12, "low-earner")
const UniformTaxYears = step(13, "uniform-tax-years")
const Name = step(14, "name")
const Email = step(15, "email")
const PhoneNumber = step(16, "phone-number")
const Estimate = step(17, "estimate")
//marriage steps START here - this is skipped if user not married or opts out
const SpouseEarningsCheck = step(18, "spouse-earnings-check", ClaimType.Marriage, {
	shouldSkipStep: (claim) => claim.MarriageClaimSkipped || !claim.QualifiesForMarriageClaim,
})
const MarriageEarnings = step(19, "marriage-earnings", ClaimType.Marriage, {
	shouldSkipStep: (claim) => claim.MarriageClaimSkipped || !claim.QualifiesForMarriageClaim,
})
const MarriagePreviousClaims = step(20, "marriage-claimed-before", ClaimType.Marriage, {
	shouldSkipStep: (claim) => claim.MarriageClaimSkipped || !claim.QualifiesForMarriageClaim,
})
const SpouseName = step(21, "spouse-name", ClaimType.Marriage, {
	shouldSkipStep: (claim) => claim.MarriageClaimSkipped || !claim.QualifiesForMarriageClaim,
})
const MarriageDate = step(22, "marriage-date", ClaimType.Marriage, {
	shouldSkipStep: (claim) => claim.MarriageClaimSkipped || !claim.QualifiesForMarriageClaim,
})
const MarriageNationalInsurance = step(23, "marriage-national-insurance", ClaimType.Marriage, {
	shouldSkipStep: (claim) => claim.MarriageClaimSkipped || !claim.QualifiesForMarriageClaim,
})
const MarriageSignature = step(24, "marriage-signature", ClaimType.Marriage, {
	shouldSkipStep: (claim) => claim.MarriageClaimSkipped || !claim.QualifiesForMarriageClaim,
})
const MarriageComplete = step(25, "marriage-complete", ClaimType.Marriage, {
	shouldSkipStep: (claim) => claim.MarriageClaimSkipped || !claim.QualifiesForMarriageClaim,
	getNextStep: () => Estimate,
})
//marriage steps END here - user resumes here if not married or opts out
const Qualifying = step(26, "qualifying")

const WorkFromHome = step(27, "work-from-home")
const TaxYears = step(28, "tax-years")
const NationalInsurance = step(31, "NI")
const ReviewClaim = step(32, "review")
const Signature = step(33, "signature")
const ClaimComplete = step(34, "complete")

const BasicInformation = step(3, "basic-information", ClaimType.MoD, {
	getPreviousStep: () => Employer,
})
const AboutYou = step(4, "about-you", ClaimType.MoD)
const DetailsPassed = step(5, "details-passed", ClaimType.MoD)

const NoTaxPaid = issueStep(99, "no-tax", ClaimType.Standard, {
	getPreviousStep: () => TaxYears,
})
const AlreadyClaimed = issueStep(100, "already-claimed")
const RequireSelfAssessment = issueStep(101, "require-sa", ClaimType.Standard, {
	getPreviousStep: () => ComplianceStatement,
})
const NoEmploymentHistory = issueStep(103, "no-employment-history")
const MarriageClaimNotValid = issueStep(110, "marriage-claim-not-valid")
const LackOfExpenses = issueStep(104, "lack-expenses")
const UniformClaimed = issueStep(106, "uniform-claimed", ClaimType.Standard, {
	getPreviousStep: () => Qualifying,
})
const EmployerDetailsNeeded = issueStep(107, "employer-details-needed", ClaimType.Standard, {
	getPreviousStep: () => Employer,
	getNextStep: () => ClaimComplete,
})
const WaitUntilNextTaxYear = issueStep(108, "wait-until-next-tax-year")
const DetailsRejected = issueStep(109, "cancelled-details-rejected")

export const ClaimSteps = {
	Welcome,
	ComplianceStatement,
	Employer,
	Occupations,
	Subscriptions,
	SubscriptionDetails,
	Postcode,
	AreYouMarried,
	PandemicWorkFromHome,
	UniformTaxYears,
	Income,
	TaxYears,
	Name,
	Email,
	PhoneNumber,
	Qualifying,
	SelfAssessedStep,
	SelfAssessedChecks,
	Estimate,
	MarriageEarnings,
	MarriagePreviousClaims,
	SpouseName,
	SpouseEarningsCheck,
	MarriageDate,
	MarriageNationalInsurance,
	MarriageSignature,
	MarriageComplete,
	WorkFromHome,
	NationalInsurance,
	ReviewClaim,
	Signature,
	ClaimComplete,

	BasicInformation,
	AboutYou,
	DetailsPassed,

	NoTaxPaid,
	AlreadyClaimed,
	RequireSelfAssessment,
	NoEmploymentHistory,
	MarriageClaimNotValid,
	LackOfExpenses,
	UniformClaimed,
	EmployerDetailsNeeded,
	WaitUntilNextTaxYear,
	DetailsRejected,

	InitialStep: Welcome,
	FinalStep: ClaimComplete,
	FinalMoDStep: DetailsPassed,
} as const

defineGlobals({
	ClaimSteps,
})

export default ClaimSteps
