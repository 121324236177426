import { useInputValidation } from "@/composition/useInputValidation"
import { fixPostcode, isPostcodeValid } from "@/utils/postcode"
import { ref, type InputHTMLAttributes } from "vue"
import { defineComponent, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"

interface Props {
	postcode: string
	setPostcode(postcode: string): void
}

const PostcodeInput: ReactiveComponent<Props, InputHTMLAttributes> = (props, { attrs }) => {
	const inputRef = ref<HTMLInputElement>()

	function getFormattedPostcode(postcode: string) {
		let result = ""
		while (postcode.includes("  ")) {
			postcode = postcode.replace("  ", " ")
		}
		for (const ch of postcode.toUpperCase().trimStart()) {
			if (/([A-Z0-9]|\s)/.test(ch)) {
				result += ch
			}
		}
		return result
	}

	useInputValidation(inputRef, () => {
		const postcode = App.CustomerClaim.Postcode
		if (!isPostcodeValid(postcode)) {
			return "Please enter a valid postcode"
		}
		props.setPostcode(fixPostcode(postcode))
		return true
	})

	return () => (
		<input
			ref={inputRef}
			type="text"
			class="form-control"
			value={props.postcode}
			onInput={useOnInput((value, e) => {
				const input = e.target as HTMLInputElement
				const newPostcode = getFormattedPostcode(value)
				input.value = newPostcode
				props.setPostcode(newPostcode)
			})}
			placeholder="Your HOME postcode"
			minlength={6}
			maxlength={8}
			required
			{...attrs}
		/>
	)
}

export default defineComponent(PostcodeInput, {
	postcode: requiredProp(String),
	setPostcode: requiredProp(Function),
})
