import ButtonNavigation from "@/components/ButtonNavigation"
import { goToNextStep } from "@/step-handler"
import type { PAYERecord } from "@/types/CompanyPAYEModels"
import { bigramSimilarity } from "@hidden-finder/didyoumean"
import { computed, ref } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { employerContainerStyles, employerListButtonStyles, employerListStyles } from "./employerStepStyles"

const EmployerStep: ReactiveComponent = () => {
	function nextSector() {
		//if ((selectedSectorIndex.value ?? 0) >= App.companyData.length - 1) {
		if (selectedSectorIndex.value === null) {
			selectedSectorIndex.value = 0
		} else {
			selectedSectorIndex.value = (selectedSectorIndex.value ?? -1) + 1
		}
	}

	function selectCategory(i: number) {
		if (selectedCategoryIndex.value !== i) {
			selectedCategoryIndex.value = i
			activeEmployer.value.LookupDataSectorCategoryIndex = i
		} else {
			selectedCategoryIndex.value = null
		}

		if (selectedSectorIndex.value !== null) {
			activeEmployer.value.LookupDataSectorIdentifier = App.companyData[selectedSectorIndex.value]?.Identifier
			if (selectedCategoryIndex.value !== null) {
				activeEmployer.value.OccupationId = App.companyData[selectedSectorIndex.value].Categories[selectedCategoryIndex.value].OccupationId
			}
		}
	}

	function confirmCategory() {
		categoryConfirmed.value = true
	}

	function compare(searchTerm: string, content: string) {
		const l = content.length
		if (content.indexOf(searchTerm) !== -1) return (l - content?.indexOf(searchTerm)) / l

		const split = content.split(" ")
		const wordSimilarities: number[] = []
		split.forEach((s) => {
			const wordSimilarity = bigramSimilarity(s ?? "", searchTerm)
			wordSimilarities.push(wordSimilarity + 1)
		})
		if (wordSimilarities.length > 1) {
			const score =
				wordSimilarities.reduce((a, b) => {
					return a + b
				}) /
					wordSimilarities.length -
				1
			if (score > 0) return score
		}

		return 0
	}

	function sortCompare(a: PAYERecord, b: PAYERecord) {
		const search = searchValue.value?.toUpperCase() ?? ""
		if (compare(search, a.Name ?? "") > compare(search, b.Name ?? "")) {
			return -1
		} else if (compare(search, a.Name ?? "") < compare(search, b.Name ?? "")) {
			return 1
		} else {
			return 0
		}
	}

	function searchCompanies() {
		console.log(searchValue.value)
		const search = searchValue.value?.toUpperCase() ?? ""
		const Source = App.companyData[selectedSectorIndex.value ?? -1]?.Categories[selectedCategoryIndex.value ?? -1].Companies
		if (!Source) return []
		const Results = Source.filter((r) => compare(search, r.Name ?? "") > 0.18).sort((a, b) => sortCompare(a, b))
		companyResults.value = Results
		showNotFoundButton.value = true
		return true
	}

	function selectEmployer(i: number) {
		if (i !== null) {
			const selectedEmployer: PAYERecord | null = companyResults.value[i]
			if (selectedEmployer !== null) {
				activeEmployer.value.EmployerName = selectedEmployer.Name ?? ""
				activeEmployer.value.PAYEReference = selectedEmployer.Reference ?? ""
			}
		}
	}

	function selectYear(year: string) {
		const y = parseInt(year)
		activeEmployer.value.YearCommenced = y
		const d = new Date()

		const years = App.CustomerClaim.Employers.flatMap((emp) => emp.YearCommenced ?? 0)
		const min = Math.min(...years)

		if (min <= d.getFullYear() - sufficientYears) {
			App.CustomerClaim.TaxYearIds = App.currentTaxYears.map((e) => e.Value)
			employerRecordsComplete.value = true
		} else {
			employerRecordsComplete.value = false
			employerIndex.value += 1
			searchValue.value = null
			selectedCategoryIndex.value = null
			categoryConfirmed.value = false
			companyResults.value = []
		}
	}

	function skipStep() {
		App.CustomerClaim.TaxYearIds = App.currentTaxYears.map((e) => e.Value)
		void goToNextStep()
	}

	const sufficientYears = 4
	const numberOfYearsEmploymentRequired = ref<number>(sufficientYears)
	const selectedSectorIndex = ref<number | null>(0)
	const selectedCategoryIndex = ref<number | null>(null)
	const categoryConfirmed = ref<boolean | null>(false)
	const searchValue = ref<string | null>(null)
	const companyResults = ref<PAYERecord[]>([])
	const showNotFoundButton = ref<boolean>(false)
	const employerIndex = ref<number>(0)
	const employerRecordsComplete = ref<boolean>(false)

	const categories = computed(() => {
		if (selectedSectorIndex.value !== null) {
			return selectedSectorIndex.value < App.companyData.length ? App.companyData[selectedSectorIndex.value]?.Categories : []
		} else {
			return null
		}
	})
	const resultsFound = computed(() => {
		return (companyResults.value ?? []).length > 0
	})
	const activeEmployer = computed(() => {
		if (App.CustomerClaim.Employers.length < employerIndex.value + 1) {
			for (let i = App.CustomerClaim.Employers.length; i <= employerIndex.value; i++) {
				App.CustomerClaim.Employers[i] = {
					EmployerName: "",
					PAYEReference: "",
					Address: "",
					YearCommenced: null,
					Postcode: "",
					LookupDataSectorIdentifier: "",
					LookupDataSectorCategoryIndex: null,
					OccupationId: null,
					IndustryId: null,
					JobTitle: "",
				}
			}
		}
		return App.CustomerClaim.Employers[employerIndex.value]
	})
	const previousEmployer = computed(() => {
		if ((employerIndex.value ?? 0) === 0) return null
		return App.CustomerClaim.Employers[employerIndex.value - 1]
	})
	const moreThanOneEmployer = computed(() => {
		return employerIndex.value > 0 ? true : false
	})

	return () => (
		<>
			{!categoryConfirmed.value && (
				<>
					{!moreThanOneEmployer.value && (
						<div class="col-12 claim-label bold">
							<p>Does your employer work in one of these industries? Please choose the best match:</p>
						</div>
					)}
					{moreThanOneEmployer.value && (
						<div class="col-12 claim-label">
							<p class="bold">
								We require at least {numberOfYearsEmploymentRequired.value} years of employment history in order to make a claim.
							</p>
							<p class="alert alert-info">
								To ensure you have a good chance of a rebate please tell us where you worked prior to {previousEmployer.value?.EmployerName}
							</p>
						</div>
					)}
					<div class={employerContainerStyles}>
						{categories.value && (
							<ul class={employerListStyles} data-open={selectedCategoryIndex.value !== null}>
								{categories.value?.map((category, index) => (
									<li key={index}>
										<button
											class={employerListButtonStyles}
											data-active={selectedCategoryIndex.value === index}
											onClick={() => selectCategory(index)}
										>
											{category.Name}
										</button>
									</li>
								))}
								{selectedCategoryIndex.value === null && categories.value.length !== 0 && (
									<li class="none">
										<button class={employerListButtonStyles} onClick={() => nextSector()}>
											None of these...
										</button>
									</li>
								)}
								{categories.value.length === 0 && (
									<li>
										<button
											class="btn btn-outline-info"
											onClick={() => {
												skipStep()
											}}
										>
											None of these apply to me
										</button>
									</li>
								)}
							</ul>
						)}
					</div>
					{selectedCategoryIndex.value !== null && (
						<div class="col-12 text-center">
							<button class="btn btn-success" onClick={() => confirmCategory()}>
								<i class="fa fa-check" /> Confirm
							</button>
						</div>
					)}
				</>
			)}
			{categoryConfirmed.value && !activeEmployer.value.EmployerName && (
				<>
					<div class="col-12 claim-label bold">
						<p>Please type the name of the company you work for and tap search.</p>
					</div>
					<div class="col-12 col-md-8 mx-auto">
						<div class="input-group">
							<input
								type="text"
								class="form-control"
								v-model={searchValue.value}
								onKeypress={(e) => {
									if (e.key === "Enter") {
										searchCompanies()
										e.preventDefault()
									}
								}}
							/>
							<button type="button" class="btn-success" onClick={() => searchCompanies()}>
								<i class="fa fa-search" />
								&nbsp; Search
							</button>
						</div>
					</div>
					<div class="col-12 col-md-8 mx-auto text-center">
						<ul class="list-group ">
							{resultsFound.value && (
								<li class="list-group-item bg-success">
									<strong>Possible matches</strong> - please choose your employer.
								</li>
							)}
							{companyResults.value?.map((result, index) => (
								<li key={index} class="list-group-item text-dark" data-reference={result.Reference}>
									<button
										class={`${employerListButtonStyles} dark`}
										onClick={() => {
											selectEmployer(index)
										}}
									>
										{result.Name}
									</button>
								</li>
							))}
							{showNotFoundButton.value && (
								<li class="list-group-item bg-info">
									<button
										class="btn btn-outline-info"
										onClick={() => {
											skipStep()
										}}
									>
										Can't find your employer? Click here.
									</button>
								</li>
							)}
						</ul>
					</div>
				</>
			)}
			{activeEmployer.value.EmployerName && (
				<>
					<div class="col-12 text-center">
						<strong>What year did you start working at {activeEmployer.value.EmployerName}?</strong>
					</div>
					<div class="col-12 col-md-6 col-lg-4 mx-auto my-3">
						<select
							class="form-control"
							onChange={(e) => {
								selectYear((e.target as HTMLSelectElement).value)
							}}
						>
							<option>Select year</option>
							{App.currentTaxYears.map((taxYear) => (
								<option key={taxYear.Key}>{taxYear.Key.split("/")[0]}</option>
							))}
						</select>
					</div>
				</>
			)}
			{employerRecordsComplete.value && <ButtonNavigation hideBack />}
		</>
	)
}

export default defineComponent(EmployerStep)
