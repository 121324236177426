import calculatorConfig from "@/calculatorConfig"
import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const TaxYearsStep: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 claim-label bold">
				<p>In each of the past 4 years was your income...</p>
			</div>
			<div class="col-12 text-center my-2">
				{Object.entries(calculatorConfig.earningsBands).map(([key, band]) => (
					<label key={key} class={[key === App.CustomerClaim.EarningsBand && "selected", "mb-1 py-1 px-4 fancy-radio d-block"]}>
						<input
							type="radio"
							class="required"
							data-validation="Please select your earnings band to continue"
							name="Income"
							value={key}
							v-model={App.CustomerClaim.EarningsBand}
						/>
						<span class="px-3">{band.title}</span>
					</label>
				))}
			</div>
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(TaxYearsStep)
