import ButtonNavigation from "@/components/ButtonNavigation"
import { useCustomValidation } from "@/composition/useCustomValidation"
import { useInputValidation } from "@/composition/useInputValidation"
import { validateUKMobile } from "@/utils/validation"
import { ref } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const PhoneNumberStep: ReactiveComponent = () => {
	const telRef = ref<HTMLInputElement>()

	useInputValidation(telRef, () => {
		if (App.CustomerClaim.noPhone) {
			return true
		}
		if (!validateUKMobile(App.CustomerClaim.Phone)) {
			return "Please provide a valid UK phone number"
		}
		return true
	})

	useCustomValidation(() => {
		if (!App.CustomerClaim.DateOfBirth) return "Please enter your date of birth"
		const MaxYear = new Date().getFullYear() - 15
		const MinYear = new Date().getFullYear() - 100
		const DoB = new Date(App.CustomerClaim.DateOfBirth)
		if (DoB.getFullYear() < MinYear || DoB.getFullYear() > MaxYear) return "Please enter a valid date of birth"
		if (App.CustomerClaim.Address1.trim().length === 0) return "Please ensure you have chosen your address"
		return true
	})

	return () => (
		<>
			<div class="col-12 mb-2 alert bg-green text-light text-center">
				Nearly at your&nbsp;
				<span class="bold">FREE ESTIMATE</span>
			</div>
			<div class="col-12 my-2 claim-label">In order to assist us with keeping in touch, it's useful for us to have your mobile number.</div>
			<div class="col-12 my-2 col-md-5">
				{App.CustomerClaim.noPhone ? (
					<input ref={telRef} key="no-phone-input" type="tel" class="form-control" placeholder="Mobile phone number" value="" disabled />
				) : (
					<input
						ref={telRef}
						key="phone-input"
						type="tel"
						v-model={App.CustomerClaim.Phone}
						class="form-control"
						id="inp_phone_23"
						v-mask="##### ######"
						placeholder="Mobile phone number"
						required
					/>
				)}
			</div>
			<div class="col-12 my-2 col-md-2 text-center">
				<strong>OR</strong>
			</div>

			<div class="col-12 my-2 col-md-5">
				<label class="custom-control custom-control-inline custom-checkbox">
					<input type="checkbox" v-model={App.CustomerClaim.noPhone} name="noPhone" class="custom-control-input" />
					<span class="custom-control-label">I don't have a mobile phone</span>
				</label>
			</div>

			<ButtonNavigation />
		</>
	)
}

export default defineComponent(PhoneNumberStep)
