import ButtonNavigation from "@/components/ButtonNavigation"
import Question from "@/components/Question"
import RadioButtons from "@/components/RadioButtons"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const SpouseEarningsCheckStep: ReactiveComponent = () => {
	return () => (
		<>
			<>
				<Question question="Has YOUR SPOUSE been employed continuously for the last 5 years?">
					<RadioButtons
						name="spouse-employed-check"
						value={App.CustomerClaim.SpouseEmployed}
						setValue={(value) => (App.CustomerClaim.SpouseEmployed = value)}
						data-validation="Please tell us if your spouse is employed"
						required
					/>
				</Question>
				<div class="col-12 claim-label bold">Does YOUR SPOUSE earn more than £12,500 per year?</div>
				<div class="col-12 text-center my-2">
					<label class="custom-control custom-control-inline custom-radio">
						<input
							type="radio"
							name="spouseEarnings"
							class="custom-control-input"
							v-model={App.CustomerClaim.SpouseEarningsAboveMinimum}
							value={false}
							required
						/>
						<span class="custom-control-label">No</span>
					</label>
					<label class="custom-control custom-control-inline custom-radio">
						<input
							type="radio"
							name="spouseEarnings"
							class="custom-control-input"
							v-model={App.CustomerClaim.SpouseEarningsAboveMinimum}
							value={true}
							required
						/>
						<span class="custom-control-label">Yes</span>
					</label>
					<label class="custom-control custom-control-inline custom-radio">
						<input
							type="radio"
							name="spouseEarnings"
							class="custom-control-input"
							v-model={App.CustomerClaim.SpouseEarningsAboveMinimum}
							value={null}
							required
						/>
						<span class="custom-control-label">Not sure</span>
					</label>
				</div>
			</>

			<ButtonNavigation />
		</>
	)
}

export default defineComponent(SpouseEarningsCheckStep)
