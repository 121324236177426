import { goToNextStep, goToPreviousStep } from "@/step-handler"
import { animateFigures } from "@/utils/animate"
import { computed, onMounted } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import TrustPilotDisplay from "./components/TrustPilotDisplay"

const CurrencyFormat = new Intl.NumberFormat("en-GB", {
	style: "currency",
	currency: "GBP",
})
const TotalFormat = new Intl.NumberFormat("en-GB", {
	maximumSignificantDigits: 3,
})

const EstimateStep: ReactiveComponent = () => {
	const termsEl = document.getElementById("calculator-terms-1") as HTMLTemplateElement

	function formatCurrency(amount: number) {
		return CurrencyFormat.format(amount)
	}
	function formatTotal(total: number) {
		return TotalFormat.format(total)
	}
	function skipMarriageClaim() {
		App.CustomerClaim.MarriageClaimSkipped = true
		window.setTimeout(() => {
			animateFigures()
		}, 100)
	}

	const showMarriageOption = computed(() => App.CustomerClaim.QualifiesForMarriageClaim && !App.CustomerClaim.MarriageClaimSkipped)

	onMounted(() => {
		animateFigures()
	})

	return () => (
		<>
			{showMarriageOption.value && (
				<div class="col-12 my-1 mt-4 px-1 px-md-3">
					<div class="jumbotron mt-0 px-2 px-md-4">
						<h2 class="mb-2 mb-sm-3">
							<span class="xbold d-block">
								<span>{App.CustomerClaim.Title}</span> <span>{App.CustomerClaim.FirstName}</span> <span>{App.CustomerClaim.Surname}</span>
							</span>
							<span class="d-block xs-small">You might be eligible for a Marriage Tax Rebate</span>
						</h2>
						<p class="text-light">It'll only take a minute to find out what you could get back - just click below to keep going.</p>
						<div class="row mt-2 mb-2 mx-0">
							<div class="col mx-2 px-0">
								<button type="button" class="btn btn-success option mb-0" onClick={() => void goToNextStep()}>
									YES, I'd like to find out <i class="fa fa-angle-double-right" />
								</button>
							</div>
						</div>
						<div class="row my-2 mx-0">
							<div class="col mx-2 px-0">
								<button type="button" class="btn btn-secondary btn-sm mb-0" onClick={() => void skipMarriageClaim()}>
									No thanks, I'd like to continue with my employment claim <i class="fa fa-angle-double-right" />
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{!showMarriageOption.value && (
				<div class="col-12 my-1 px-1 px-md-3">
					<div class="jumbotron mt-0 px-2 px-md-4">
						{!!App.CustomerClaim && (
							<h2 class="mb-2 mb-sm-3">
								<span class="xbold d-block xs-xsmall">
									<span>{App.CustomerClaim.Title}</span> <span>{App.CustomerClaim.FirstName}</span> <span>{App.CustomerClaim.Surname}</span>
								</span>
								<span class="d-block xs-xsmall">Your Refund Estimate</span>
								<span class="d-block xs-xsmall">Based on your employment</span>
							</h2>
						)}
						<div class="text-center text-uppercase mb-2 mb-md-3">
							<div class="d-inline-block px-3 py-3 px-sm-4 py-sm-4 white-box box-md">
								<span class="xbold count-up-lg">£</span>
								<span class="xbold countup count-up-lg" data-value={App.CustomerClaim.MaxRefund}>
									-
								</span>
								<span class="d-block xs-xsmall count-up-subtext">Largest Refund</span>
							</div>
						</div>
						<div class="row m-0 text-uppercase">
							{!!App.CustomerClaim.SampleOccupationRefunds && App.CustomerClaim.SampleOccupationRefunds.length > 0 && (
								<div class="col col-sm mx-2 px-2 py-2 px-sm-4 py-sm-4 my-2 my-md-3 white-box">
									<span class="d-block xs-xsmall count-up-subtext">Recent Similar Refunds</span>
									<span class="d-block pt-1 count-up-md">
										{App.CustomerClaim.SampleOccupationRefunds.map((r) => (
											<span key={r}>
												<span class="d-inline-block p-1">
													<span class="xbold">£</span>
													<span class="xbold countup" data-value={r}>
														-
													</span>
													<span>
														{App.CustomerClaim.SampleOccupationRefunds.indexOf(r) < App.CustomerClaim.SampleOccupationRefunds.length - 1
															? ","
															: ""}
													</span>
												</span>
											</span>
										))}
									</span>
								</div>
							)}

							<div class="col col-sm mx-2 px-2 py-2 px-sm-4 py-sm-4 my-2 my-md-3 d-flex flex-column white-box">
								<span class="d-block xs-xsmall count-up-subtext">Average Refund</span>
								<span class="d-flex pt-0 align-items-center justify-content-center count-up-md" style="flex:1;">
									<span class="xbold">£</span>
									<span class="xbold countup" data-value={App.CustomerClaim.AverageRefund}>
										-
									</span>
								</span>
							</div>
						</div>
						<div class="row mt-2 mb-2 mx-0">
							<div class="col mx-2 px-0">
								<button type="button" class="btn btn-success option mb-0" onClick={() => void goToNextStep()}>
									Complete your claim online in less than 60 seconds <i class="fa fa-angle-double-right" />
								</button>
							</div>
						</div>
						<div class="row mt-4 mx-0">
							<div class="col mx-2 px-0">
								<button
									type="button"
									class="btn btn-danger option mb-0"
									onClick={() => void goToPreviousStep()}
									style={{ border: "none", textTransform: "none", opacity: 0.9, fontSize: "1rem" }}
								>
									<i class="fa fa-angle-double-left" /> Adjust my details
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
			<div class="col-12 my-1 px-1 px-md-3">
				<div class="jumbotron inverse mt-2 mt-md-0">
					<hr class="my-1 my-md-3 d-none d-sm-block" />
					<h3 class="xbold d-none d-sm-block">What our customers think</h3>
					<hr class="my-1 my-md-3 d-none d-sm-block" />
					<TrustPilotDisplay />
				</div>
			</div>
			<div class="col-12 my-1 px-1 px-md-3">
				<div class="jumbotron">
					<div class="row">
						<div class="col-12 col-md-4 mb-2">
							<div class="USP">
								<i class="fa fa-money" />
								<p class="xbold totalrefunds increment" id="div_totalrefunds">
									{formatCurrency(claimsTotalMin)}
								</p>
								<p>Total claims</p>
							</div>
						</div>
						<div class="col-12 col-md-4 mb-2">
							<div class="USP">
								<i class="fa fa-area-chart" />
								<p class="xbold refundcount increment" id="div_refundcount">
									{formatTotal(noOfClaimsMin)}
								</p>
								<p>Successful claims</p>
							</div>
						</div>
						<div class="col-12 col-md-4 mb-2">
							<div class="USP">
								<i class="fa fa-hourglass-end" />
								<p class="xbold">{"<"} 60</p>
								<p>Seconds to claim</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 my-1 p-1 px-md-3">
				<div class="jumbotron inverse">
					<div class="row" style="overflow: hidden;">
						<div class="col-12 text-center">
							<div
								class="fb-like"
								data-href="https://www.facebook.com/@Model.brandType.FacebookPageUrl"
								data-layout="standard"
								data-action="like"
								data-size="small"
								data-show-faces="true"
								data-share="true"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="scrollableInfo" innerHTML={termsEl.innerHTML} />
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 mt-4 mb-2 text-center">
				<small>Complete your claim in less than 60 seconds</small>
				<button type="button" class="btn btn-success option mb-0" style="width:auto;" onClick={() => void goToNextStep()}>
					Claim Now <i class="fa fa-angle-double-right" />
				</button>
			</div>
			<div class="position-fixed d-md-none step-footer-fixed">
				<div class="inner-container py-2">
					<div class="row align-items-center">
						<div class="col text-center">
							<small>Complete your claim in less than 60 seconds</small>
							<button type="button" class="btn btn-success option mb-0" style="width:auto;" onClick={() => void goToNextStep()}>
								Claim Now <i class="fa fa-angle-double-right" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default defineComponent(EstimateStep)
