import { goToNextStep } from "@/step-handler"
import { showTrustPilotModal } from "@/utils/miscPageUtils"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const MarriageCompleteStep: ReactiveComponent = () => {
	const qualifiesForJobClaim = computed(() => {
		const qualifies = App.CustomerClaim.Employed !== false && App.CustomerClaim.EarningsAboveMinimum !== false
		return qualifies
	})
	async function exitMarriageClaim() {
		App.CustomerClaim.MarriageClaimSkipped = true
		await goToNextStep()
	}
	return () =>
		!!App.CustomerClaim.MarriageData && (
			<>
				<div class="col-12 mb-1 text-center">
					<h3 class="bold">Thanks!</h3>
					<div class="col-12 my-2 text-center">
						<p>
							Your unique claim reference is{" "}
							<span class="bold">
								MTR
								{App.CustomerClaim.MarriageData.Claim_Id}
							</span>
						</p>
					</div>
					<p class="bold alert alert-primary my-4">Take a photo or screenshot of this QR code with your phone now!</p>
					<img
						alt="QR Code for your partner to scan with their phone"
						style="width: 30%; min-width: 250px;"
						src={App.CustomerClaim.MarriageData.PartnerQRCodeB64}
					/>
					<p class="bold alert alert-primary my-4">
						Your spouse <span style="text-decoration: underline;">must</span> follow the QR code on their phone and complete a few quick
						questions before we can check if you are due a refund.
					</p>
				</div>
				<hr />
				{qualifiesForJobClaim.value && (
					<div class="col-12 my-2 text-center">
						<div class="row my-2 mx-0">
							<div class="col mx-2 px-0">
								<p class="alert alert-info">
									Are you sure you're taken a screen shot of the QR code?
									<button type="button" class="btn btn-success bg-green mb-0" onClick={() => void exitMarriageClaim()}>
										Keep going to find out how much more you could claim <i class="fa fa-angle-double-right" />
									</button>
								</p>
							</div>
						</div>
					</div>
				)}
				{!qualifiesForJobClaim.value && (
					<>
						<div class="col-12 my-2 text-center">
							<p class="bold">Rate your experience so far:</p>
							<button
								onClick={() => void showTrustPilotModal(App.CustomerClaim.MarriageData!.Claim_Id, App.CustomerClaim.TrustPilotSiteUrl)}
								class="btn btn-success"
							>
								I would give it 5 stars
							</button>
						</div>
						<div class="col-12 mt-2 mb-5 text-center">
							<div class="mb-2">Why not?</div>
							<a href={`/feedback/unhappy?u=${App.CustomerClaim.UserId}`} class="btn btn-danger">
								Leave feedback
							</a>
						</div>
					</>
				)}
			</>
		)
}

export default defineComponent(MarriageCompleteStep)
