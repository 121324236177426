import type {
	AddressResult,
	ClaimCustomer,
	CustomerIOEvent,
	EmployerReference,
	EnumEntry,
	Guid,
	Id,
	MODClaim,
	OccupationSearch,
	OccupationSerializable,
	TaxYearViewModel,
} from "@/types"
import type { SectorRecord } from "@/types/CompanyPAYEModels"
import { defineGlobals } from "vue-utils"
import { httpJsonRequest, httpJsonRequestJsonResponse, httpRequest, httpRequestJsonResponse, RequestMethod } from "./httpService"

export async function getIndustrySectors(brandTypeId: Id): Promise<EnumEntry[]> {
	return await httpRequestJsonResponse(`/api/ui/industries/${brandTypeId}`, RequestMethod.GET)
}

export async function getOccupations(industryId: Id): Promise<EnumEntry[]> {
	return await httpRequestJsonResponse(`/api/ui/occupations/${industryId}`, RequestMethod.GET)
}

export async function getOccupationsAll(): Promise<OccupationSerializable[]> {
	return await httpRequestJsonResponse("/api/ui/occupations-all", RequestMethod.GET)
}

export async function getOccupationsFiltered(industryId: Id): Promise<EnumEntry[]> {
	return await httpRequestJsonResponse(`/api/ui/occupations/${industryId}/filtered`, RequestMethod.GET)
}

export async function getIndustrySectorsFiltered(brandTypeId: Id): Promise<EnumEntry[]> {
	return await httpRequestJsonResponse(`/api/ui/industries/${brandTypeId}/filtered`, RequestMethod.GET)
}

export async function addressLookup(postcode: string): Promise<AddressResult[]> {
	return await httpRequestJsonResponse(`/api/ui/addresslookup/${postcode}`, RequestMethod.GET)
}

export async function getAddress(addressId: string): Promise<AddressResult> {
	return await httpRequestJsonResponse(`/api/ui/getaddress/${addressId}`, RequestMethod.GET)
}

defineGlobals({ addressLookup, getAddress })

export async function getTaxYears(): Promise<EnumEntry[]> {
	return await httpRequestJsonResponse("/api/ui/taxyears", RequestMethod.GET)
}

export async function getDetailedTaxYears(): Promise<TaxYearViewModel[]> {
	return await httpRequestJsonResponse("/api/ui/taxyears/full", RequestMethod.GET)
}

export async function getEmployerReferences(search: string, count = 100): Promise<EmployerReference[]> {
	return await httpRequestJsonResponse(`/api/ui/employers/search/?search=${encodeURIComponent(search)}&count=${count}`, RequestMethod.GET)
}

export async function saveClaimToServer(claim: ClaimCustomer): Promise<ClaimCustomer> {
	const result = await httpJsonRequestJsonResponse<ClaimCustomer>("/api/ui/customerclaim/insert", claim, RequestMethod.POST)
	localStorage.setItem("CustomerClaim", JSON.stringify(result))
	return result
}

export async function saveMODClaimtoServer(claim: MODClaim): Promise<void> {
	await httpJsonRequest("/api/ui/modclaim/insert", claim, RequestMethod.POST)
}

export async function generateDownloadKey(userId: string): Promise<void> {
	await httpRequest(`/api/ui/customerclaim/${userId}/createdownloadkey`, RequestMethod.POST)
}

export async function cancelClaim(userId: Guid, claimId: Id): Promise<boolean> {
	return await httpRequestJsonResponse(`/api/ui/customerclaim/${userId}/cancel/${claimId}`, RequestMethod.POST)
}

export async function occupationSearch(search: string): Promise<OccupationSearch[]> {
	return await httpJsonRequestJsonResponse("/api/ui/occupationsearch", search, RequestMethod.POST)
}

export async function getCompanyPAYEData(): Promise<SectorRecord[]> {
	return await httpRequestJsonResponse("/api/companies-paye/sectors", RequestMethod.GET)
}

export async function insertQualifyingCheck(question: string, answer: string, allowed: boolean, claimId: Id, code: string): Promise<number> {
	return await httpJsonRequestJsonResponse(
		"/api/ui/qualifyingcheck/insert",
		{
			QuestionText: question,
			Answer: answer,
			Allowed: allowed,
			ClaimId: claimId,
			CheckCode: code,
		},
		RequestMethod.POST
	)
}

export async function logCustomerIOEvent(claimId: Id, data: CustomerIOEvent): Promise<void> {
	await httpJsonRequest(`/api/ui/customerclaim/${claimId}/cioevent/log`, data, RequestMethod.POST)
}

export async function checkEmailDidYouMean(claimId: Id, email: string): Promise<string | null> {
	return await httpJsonRequestJsonResponse(`/api/ui/check-email/${claimId}`, email, RequestMethod.POST)
}
