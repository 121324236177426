import { logCustomerIOEvent, saveClaimToServer } from "@/apis"
import { useLoading } from "@/composition/useLoading"
import { modEmployers, modIndustryId } from "@/constants"
import ClaimSteps, { ClaimType, type ClaimStepData } from "@/steps"
import { ClaimStatus } from "@/types"
import { getLatestJob } from "@/utils/jobDates"
import { checkFields } from "@/utils/validation"
import { getNextStep, getPreviousStep } from "./getStep"
import runPostSaveActions from "./saveStep"
import { setClaimStep, type SetClaimSetOptions } from "./setStep"

export async function goToNextStep(nextStep?: ClaimStepData, options?: SetClaimSetOptions): Promise<boolean> {
	const ok = await checkFields($("#Claim form"))
	if (!ok) {
		return false
	}

	if (!nextStep && !getNextStep(CurrentStep)) {
		return false
	}

	//SWITCH TO MARRIAGE CLAIM WHEN APPROPRIATE
	if (CurrentStep.type === ClaimType.Standard && CurrentStep.step === ClaimSteps.Estimate.step && isMarriageClaim()) {
		nextStep = ClaimSteps.SpouseEarningsCheck
	}

	if (CurrentStep.type === ClaimType.Standard && CurrentStep.step >= ClaimSteps.Signature.step && CurrentStep.step < ClaimSteps.FinalStep.step) {
		App.CustomerClaim.ClaimStatus = ClaimStatus.AbandonedBeforePayment
	}

	App.CustomerClaim = await useLoading().runAction(saveClaimToServer(App.CustomerClaim))
	await runPostSaveActions(nextStep, options)

	return true
}

function isMoDClaim(): boolean {
	//check for MOD
	//THIS IS WHERE WE DEFINE THE PARAMETERS THAT DEFINE A 'MOD' CLAIM
	// Check for employer name that could be MOD and change claim accordingly
	const latestJob = getLatestJob(App.CustomerClaim.EmploymentRecords)
	if (!latestJob) {
		return false
	}
	const employerName = latestJob.EmployerName?.trim()?.toLowerCase()
	return latestJob.IndustryId === modIndustryId || modEmployers.some((employer) => employer === employerName)
}

function isMarriageClaim(): boolean {
	return (App.CustomerClaim.QualifiesForMarriageClaim ?? false) && !App.CustomerClaim.MarriageClaimSkipped
}

export async function goToPreviousStep(previousStep?: ClaimStepData, options?: SetClaimSetOptions) {
	if (!previousStep) {
		const previous = getPreviousStep(CurrentStep)
		if (!previous) {
			return false
		}
		previousStep = previous
	}

	// If claim status was changed to an error status then change it back
	if ((App.CustomerClaim.ClaimStatus as number) > 20 && previousStep.step < 50) {
		App.CustomerClaim.ClaimStatus = ClaimStatus.AbandonedBeforePayment
	}

	await setClaimStep(previousStep, options)

	//if (ClaimStage > 1 || window.location.hash.indexOf('step') > -1) {
	/*if (ClaimStage > getClaimStepNo('job') || window.location.hash.indexOf('step') > -1) {
        var hashString = '#step' + ClaimStage;
        history.pushState('', '', hashString);
    }*/

	const event = {
		name: "click_back",
		data: {
			step_number: previousStep.step,
		},
	}
	void logCustomerIOEvent(App.CustomerClaim.ClaimId, event)
	return true
}
