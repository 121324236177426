import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const WelcomeStep: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 col-md-4 mx-auto text-center">
				<p class="bold">It only takes 2 minutes!</p>
				<p>
					<i class="fa fa-hourglass-2 fa-3x rotating slow" />
				</p>
				<p>
					<small>Click the button, tell us a little about yourself, and let us do the work.</small>
				</p>
			</div>
			<ButtonNavigation hideBack nextText="Find out how much you're owed" />
		</>
	)
}

export default defineComponent(WelcomeStep)
