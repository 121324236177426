import type { App } from "vue"

import { VueMaskDirective } from "v-mask"
import ButtonNavigation from "./components/ButtonNavigation"
import ClaimStep from "./components/ClaimStep"
import CurrencyInput from "./components/CurrencyInput"
import Question from "./components/Question"
import RadioButtons from "./components/RadioButtons"
import { LoadingWrapper } from "./composition/useLoading"
import { ModalsWrapper } from "./modals"
import DisplayCurrentStep from "./steps/DisplayCurrentStep"

export class TaxRebateVuePlugin {
	install(app: App) {
		app.directive("mask", {
			beforeMount: (el, binding) => VueMaskDirective.bind(el as Element, binding),
			updated: (el, directive) => VueMaskDirective.componentUpdated(el as Element, directive),
			unmounted: (el) => VueMaskDirective.unbind(el as Element),
		})

		app.component("RadioButton", RadioButtons)
		app.component("Question", Question)
		app.component("CurrencyInput", CurrencyInput)
		app.component("ButtonNavigation", ButtonNavigation)

		app.component("ClaimStage", ClaimStep)
		app.component("LoadingWrapper", LoadingWrapper)
		app.component("ModalsWrapper", ModalsWrapper)

		app.component("DisplayCurrentStep", DisplayCurrentStep)
	}
}
