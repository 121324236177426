import type { Employer, EmploymentRecord, EmploymentRecordDate, Nullable } from "@/types"

export function compareEmploymentDates(d1: EmploymentRecordDate, d2: EmploymentRecordDate): number {
	if (d1 === d2) return 0
	if (d1.Year < d2.Year || (d1.Year === d2.Year && d1.Month < d2.Month)) return -1
	if (d2.Year < d1.Year || (d2.Year === d1.Year && d2.Month < d1.Month)) return 1
	return 0
}

export function getLatestJob(records: EmploymentRecord[]): Nullable<EmploymentRecord> {
	if (records.length === 0) {
		return null
	}

	const today: EmploymentRecordDate = { Year: new Date().getFullYear(), Month: new Date().getMonth() + 1 }
	const startOfTime: EmploymentRecordDate = { Year: 0, Month: 1 }

	const sortedJobs = [...records].sort((r1, r2) => {
		const cmp = compareEmploymentDates(r1.Finished ?? today, r2.Finished ?? today) * -1
		if (cmp !== 0) {
			return cmp
		}
		return compareEmploymentDates(r1.Started ?? startOfTime, r2.Started ?? startOfTime)
	})

	return sortedJobs[0]
}

export function calculateTotalYearsEmployed(records: Employer[]): number {
	const current = new Date().getFullYear()
	return current - Math.min(...records.map((x) => x.YearCommenced ?? current))
}
