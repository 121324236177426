import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import SplitDateInput from "../components/SplitDateInput"

const MarriageDateStep: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col col-md-10 col-lg-8 mx-auto">
				<div class="row">
					<div class="col-12">
						<div class="row">
							<div class="col-12 text-center">
								<p>When were you married?</p>
								<SplitDateInput
									class="mx-0 px-0"
									date={App.CustomerClaim.MarriageDate}
									setDate={(date) => (App.CustomerClaim.MarriageDate = date)}
									label="Your Marriage Date"
									required
								/>
							</div>
						</div>
					</div>
					<div class="col-12 align-middle mt-2">
						<ButtonNavigation />
					</div>
				</div>
			</div>
		</>
	)
}

export default defineComponent(MarriageDateStep)
