import { saveClaimToServer, saveMODClaimtoServer } from "@/apis"
import { useLoading } from "@/composition/useLoading"
import ClaimSteps, { ClaimType, type ClaimStepData } from "@/steps"
import { ClaimStatus } from "@/types"
import { calculateTotalYearsEmployed } from "@/utils/jobDates"
import { getNextStep } from "./getStep"
import runQualifyingChecks from "./qualifying-checks"
import { setClaimStep, type SetClaimSetOptions } from "./setStep"

const { runAction } = useLoading()

export default async function runPostSaveActions(requestedNextStep?: ClaimStepData, options?: SetClaimSetOptions) {
	App.InitialLoadWithExistingClaim = false

	//check if marriage claim data has come back as invalid and send to error step
	if (CurrentStep.type === ClaimType.Marriage && App.CustomerClaim.MarriageData?.Validity && App.CustomerClaim.MarriageData?.Validity > 0) {
		console.log("Non valid marriage claim detected")
		await setClaimStep(ClaimSteps.MarriageClaimNotValid)
		return
	}

	const nextStep = getRequestedNextStep(requestedNextStep)

	//save MOD claim if needed
	if (CurrentStep.type === ClaimType.MoD && App.MODClaim.CustomerId > 0 && App.MODClaim.MobileNumber.length > 0) {
		await runAction(saveMODClaimtoServer(App.MODClaim))
		localStorage.setItem("MODClaim", JSON.stringify(App.MODClaim))
	}

	if (nextStep === ClaimSteps.FinalStep) {
		//TODO Can these be moved as individual qualifying checks? Or is there a reason they need to be further up

		if (testMode) console.log("Claim complete!! Going to do some stuff now...")

		if (App.CustomerClaim.Employers.some((er) => er.PAYEReference === "") && !App.CustomerClaim.ConsentContactEmployers) {
			App.CustomerClaim.ClaimStatus = ClaimStatus.NotProvidedPAYEOrSARPermission
			App.CustomerClaim = await runAction(saveClaimToServer(App.CustomerClaim))
			await setClaimStep(ClaimSteps.EmployerDetailsNeeded)
			return
		}

		if (calculateTotalYearsEmployed(App.CustomerClaim.Employers) < 1 && !App.CustomerClaim.ConsentContactEmployers) {
			App.CustomerClaim.ClaimStatus = ClaimStatus.SubmitNextTaxYear
			App.CustomerClaim = await runAction(saveClaimToServer(App.CustomerClaim))
			await setClaimStep(ClaimSteps.WaitUntilNextTaxYear)
			return
		}

		App.CustomerClaim.ClaimStatus =
			App.CustomerClaim.Employers.some((er) => er.PAYEReference === "") && App.CustomerClaim.ConsentContactEmployers
				? ClaimStatus.NotCompletedEmployerDetails
				: ClaimStatus.CompletePaymentSuccessful
		App.CustomerClaim = await runAction(saveClaimToServer(App.CustomerClaim))

		if (App.CustomerClaim.ClaimStatus === ClaimStatus.CancelledDetailsRejected) {
			await setClaimStep(ClaimSteps.DetailsRejected)
			return
		}

		if (!App.CustomerClaim.IsRepeatCustomer) {
			window.location.href = `${conversionUrl}/?cid=${App.CustomerClaim.ClaimId}` //'/payments';
		} else if (App.CustomerClaim.selfAssessed === false && (App.CustomerClaim.ClaimStatus as number) < 20) {
			// Repeat customer
			if (testMode) console.log("Repeat customer - not going to do stuff after all...")
			if (testMode) console.log("Redirecting to final page...")
			window.location.href = `${repeatCustomerConversionUrl}/${App.CustomerClaim.UserId}`
			return
		}
	}

	const successful = await runQualifyingChecks({
		claim: App.CustomerClaim,
		nextStep,
		requestedNextStep,
		stepOptions: options ?? {},
	})
	if (!successful) {
		return
	}
	await setClaimStep(nextStep, options)
}

function getRequestedNextStep(requested?: ClaimStepData) {
	if (requested) {
		return requested
	}
	const next = getNextStep(CurrentStep)
	if (!next) {
		throw new Error("No next step")
	}
	return next
}
