import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { watch } from "vue"

const ReviewStep: ReactiveComponent = () => {
	function updateSelfAssessed() {
		//When changing the self assessed option to "no" or "not sure", need to reset the SAC options
		const selfAssessed = App.CustomerClaim.selfAssessed

		if (selfAssessed === false || selfAssessed === null) {
			const claim = App.CustomerClaim as unknown as Record<string, unknown>
			for (const key in App.CustomerClaim) {
				if (key.startsWith("SAC")) {
					claim[key] = selfAssessed
				}
			}
		}
	}
	watch(() => App.CustomerClaim.selfAssessed, updateSelfAssessed)

	return () => (
		<>
			<div class="col-12 claim-label bold">Are you required to prepare a self-assessment tax return?</div>
			<div class="col-12 text-center my-2">
				<label class="custom-control custom-control-inline custom-radio">
					<input type="radio" name="selfassessed" class="custom-control-input" v-model={App.CustomerClaim.selfAssessed} value={false} required />
					<span class="custom-control-label">No</span>
				</label>
				<label class="custom-control custom-control-inline custom-radio">
					<input type="radio" name="selfassessed" class="custom-control-input" v-model={App.CustomerClaim.selfAssessed} value={true} required />
					<span class="custom-control-label">Yes</span>
				</label>
				<label class="custom-control custom-control-inline custom-radio">
					<input type="radio" name="selfassessed" class="custom-control-input" v-model={App.CustomerClaim.selfAssessed} value={null} required />
					<span class="custom-control-label">Not sure</span>
				</label>
			</div>
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(ReviewStep)
