import ButtonNavigation from "@/components/ButtonNavigation"
import { useEmailDidYouMean } from "@/composition/useEmailDidYouMean"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import AddressInput from "./components/AddressInput"
import SelectGender from "./components/SelectGender"
import SelectTitle from "./components/SelectTitle"
import SplitDateInput from "./components/SplitDateInput"

const NameStep: ReactiveComponent = () => {
	useEmailDidYouMean(
		App.CustomerClaim.ClaimId,
		() => App.CustomerClaim.Email,
		(newEmail) => {
			//Set both email & email confirm - assumption is at this point validation has passed they are the same
			App.CustomerClaim.Email = newEmail
			App.CustomerClaim.emailConfirm = newEmail
		}
	)

	function showGender(): boolean {
		return App.CustomerClaim.MarriageData !== null
	}

	return () => (
		<>
			<div class="col-12 my-2 claim-label bold">Your details</div>
			<div class={["col-12 my-2", showGender() ? "col-md-6" : "col-md-12"]}>
				<SelectTitle title={App.CustomerClaim.Title} setTitle={(title) => (App.CustomerClaim.Title = title)} />
			</div>
			{showGender() && (
				<div class="col-12 col-md-6 my-2">
					<SelectGender
						gender={App.CustomerClaim.MarriageData?.Gender ?? null}
						setGender={(gender) => {
							if (App.CustomerClaim.MarriageData) {
								App.CustomerClaim.MarriageData.Gender = gender
							}
						}}
					/>
				</div>
			)}
			<div class="col-12 my-2 col-md-6">
				<input
					type="text"
					class="form-control"
					name="first_name"
					autocomplete="on"
					v-model={App.CustomerClaim.FirstName}
					placeholder="Your first name(s)"
					required
				/>
			</div>
			<div class="col-12 my-2 col-md-6">
				<input
					type="text"
					class="form-control"
					name="second_name"
					autocomplete="on"
					v-model={App.CustomerClaim.Surname}
					placeholder="Your surname"
					required
				/>
			</div>
			<AddressInput address={App.CustomerClaim} updateAddress={(data) => Object.assign(App.CustomerClaim, data)} />
			<div class="col-12 my-2 col-md-6">
				<SplitDateInput
					class="mx-0 px-0"
					date={App.CustomerClaim.DateOfBirth}
					setDate={(date) => (App.CustomerClaim.DateOfBirth = date)}
					label="Date of birth"
					required
				/>
			</div>
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(NameStep)
