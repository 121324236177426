/* eslint-disable @typescript-eslint/no-misused-promises */
import { httpJsonRequest, httpJsonRequestJsonResponse, httpRequest, RequestMethod } from "@/apis/httpService"
import { useLoading } from "@/composition/useLoading"
import { showModalMessage } from "@/modals"
import type { CustomerClaimRecordForExport, Nullable } from "@/types"
import moment from "moment"
import { createApp, reactive, type App } from "vue"
import { defineGlobals } from "vue-utils"
import { generateDownloadKey } from "../apis"
import { TaxRebateVuePlugin } from "../TaxRebateVuePlugin"

interface Outcome {
	logicSelector: string
	id: string
	renderAction: string
}

/*DECISION FLOW LOGIC*/
const ClaimLookup = reactive({
	Results: [] as CustomerClaimRecordForExport[],
	SelectedClaimId: "none",
	EnquirySent: false,
	SelectedClaim: null as Nullable<CustomerClaimRecordForExport>,
})

const { runAction } = useLoading()
let app: App | null = null

//initialize vue context for Decision flow
function lookupVueInit() {
	if (app) {
		app.unmount()
	}
	app = createApp({
		data: () => ({ ClaimLookup }),
		methods: {
			frontEndDateFormat(date: string) {
				return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
			},
		},
	})
	app.use(new TaxRebateVuePlugin())
	app.mount("#Contact")

	defineGlobals({ ClaimLookup })
	return app
}

export function lookupVueInitialize() {
	if ($("#ClaimLookup").length) {
		lookupVueInit()
	}
}

$(document).on("change", "#FlowItems", function () {
	const optionSelected = $("option:selected", this as Document)
	const renderAction = optionSelected.data("render-action") as string
	const redirect = optionSelected.data("redirect") as Nullable<string>

	if (!!redirect && redirect !== "") {
		location.href = redirect
	}

	$("#Contact").load(renderAction, () => {
		lookupVueInit()
	})
})

//action on clicking look up claim
$(document).on("click", "#btnLookupClaim", async (e) => {
	e.preventDefault()
	//console.log('looking up claims...');
	const email = $("input[name=email]").val() as string
	const claimref = $("input[name=claimref]").val() as string
	const ni = $("input[name=NI]").val() as string

	ClaimLookup.Results = await runAction(lookupClaims(email, claimref, ni))
})

//action on clicking look up claim for print
$(document).on("click", "#btnLookupClaimForPrint", async (e) => {
	e.preventDefault()
	//console.log('looking up claims...');
	const email = $("input[name=email]").val() as string
	const claimref = $("input[name=claimref]").val() as string
	const ni = $("input[name=NI]").val() as string

	if (email.length > 0 && claimref.length > 0 && ni.length > 0) {
		ClaimLookup.Results = await runAction(lookupClaimsForPrint(email, claimref, ni))
	} else {
		void showModalMessage("Error", "You need to provide all 3 fields in order to look up your claim.")
	}
})

//action to redirect to pdf
$(document).on("click", ".btn.print", async function (e) {
	e.preventDefault()
	const userid = $(this).data("user") as string
	const url = $(this).data("link") as string

	await generateDownloadKey(userid)
	window.location.href = url
	//window.open(url);
})

//action on clicking 'I don't have these details' on claim lookup
$(document).on("click", "button.nodetails", (e) => {
	e.preventDefault()

	const outcome = lookupOutcome("var ClaimGross = 0;\r\nvar DaysSinceForms = -2")
	if (outcome) {
		const renderAction = `/Home/${outcome.renderAction}/${outcome.id}`
		//console.log(renderAction);

		$("#Contact").load(renderAction, () => {
			lookupVueInit()
		})
	}
})

function lookupOutcome(statement: string) {
	if (testMode) console.log(`looking up outcome for statement: ${statement}`)
	const outcomeElements = $("span.outcome")
	const outcomes: Outcome[] = []

	outcomeElements.each(function () {
		const e = $(this)
		if (testMode) console.log(e.attr("data-LogicSelector"))
		outcomes.push({
			logicSelector: e.attr("data-LogicSelector") as string,
			id: e.attr("data-Id") as string,
			renderAction: e.data("render-action") as string,
		})
	})

	if (testMode) console.log(outcomes)

	for (let i = 0; i < outcomes.length; i++) {
		const result = evaluateOutcome(statement, outcomes[i].logicSelector)
		if (result) {
			if (testMode) console.log(outcomes[i].logicSelector)
			return outcomes[i]
		}
	}
	return undefined
}

function evaluateOutcome(statement: string, outcome: string): unknown {
	//TODO This should be removed ASAP
	// eslint-disable-next-line @typescript-eslint/no-implied-eval
	return new Function(`${statement};return ${outcome}`)()
}

//action on picking your claim from looked-up claims
$(document).on("click", "a.proceed", function (e) {
	e.preventDefault()
	ClaimLookup.SelectedClaimId = $(this).data("claimid") as string
	const claimgross = $(this).data("claimgross") as Nullable<string>
	const dayssinceformsreceived = $(this).data("dayssinceformsreceived") as string
	const statementsToEvaluate = []

	//var selectedClaim = ClaimLookup.Results.find(function (id) { return ClaimReference == id; });

	ClaimLookup.SelectedClaim = ClaimLookup.Results.find((obj) => obj.ClaimReference === ClaimLookup.SelectedClaimId) ?? null

	if (testMode) console.log(ClaimLookup.SelectedClaim)

	if (claimgross !== null && claimgross !== "") {
		statementsToEvaluate.push(`var ClaimGross = ${claimgross};`)
	} else {
		statementsToEvaluate.push("var ClaimGross = null;")
	}
	if (dayssinceformsreceived !== null && dayssinceformsreceived !== "") {
		statementsToEvaluate.push(`var DaysSinceForms = ${dayssinceformsreceived};`)
	} else {
		statementsToEvaluate.push("var DaysSinceForms = null;")
	}

	const statementToEvaluate = statementsToEvaluate.join("\r\n")

	const outcome = lookupOutcome(statementToEvaluate)
	if (outcome) {
		const renderAction = `/Home/${outcome.renderAction}/${outcome.id}`
		//console.log(renderAction);

		$("#Contact").load(renderAction, () => {
			lookupVueInit()
		})
	}
})

//action on clicking send claim enquiry
$(document).on("click", "#btnContact", async (e) => {
	e.preventDefault()

	await runAction(
		sendClaimEnquiry(
			$("input[name=firstname]").val() as string,
			$("input[name=surname]").val() as string,
			$("input[name=email]").val() as string,
			$("input[name=refund]").val() as string,
			$("input[name=NI]").val() as string,
			$("input[name=claimref]").val() as string,
			$("input[name=postcode]").val() as string,
			$("input[name=address]").val() as string
		)
	)
	ClaimLookup.EnquirySent = true
})

//action on clicking send general enquiry
$(document).on("click", "#btnGeneralContact", async (e) => {
	e.preventDefault()

	await runAction(
		sendGeneralEnquiry(
			$("input[name=firstname]").val() as string,
			$("input[name=surname]").val() as string,
			$("input[name=email]").val() as string,
			$("input[name=claimref]").val() as string,
			$("input[name=postcode]").val() as string,
			$("textarea[name=message]").val() as string
		)
	)
	ClaimLookup.EnquirySent = true
})

$(document).on("click", "#btnResendForms", async (e) => {
	e.preventDefault()

	await runAction(cloneClaim(ClaimLookup.SelectedClaimId))
	ClaimLookup.EnquirySent = true
})

$(document).on("click", "#btnReprintForms", async (e) => {
	e.preventDefault()

	await runAction(reprintClaim(ClaimLookup.SelectedClaimId))
	ClaimLookup.EnquirySent = true
})

$(document).on("click", "#btnUnsubscribe", async (e) => {
	e.preventDefault()

	const email = $("input[name=email]").val() as string
	const type = $("input[name=unsub]:checked").val() as "claimonly" | "all"

	await runAction(unsubscribe(email, type))
	$("#UnsubscribeSuccess").show()
	$("#UnsubscribeForm").hide()
})

async function lookupClaims(email: string, claimref: string, ni: string): Promise<CustomerClaimRecordForExport[]> {
	const search = {
		ClaimReference: claimref,
		EmailAddress: email,
		NINumber: ni,
		ClaimStatus: -2,
		BrandType: -1,
		Brand: -1,
	}
	return await httpJsonRequestJsonResponse("/api/ui/claims/lookup", search, RequestMethod.POST)
}

async function lookupClaimsForPrint(email: string, claimref: string, ni: string): Promise<CustomerClaimRecordForExport[]> {
	const search = {
		ClaimReference: claimref,
		EmailAddress: email,
		NINumber: ni,
		ClaimStatus: -2,
		BrandType: -1,
		Brand: -1,
	}
	return await httpJsonRequestJsonResponse("/api/ui/claims/lookup/print", search, RequestMethod.POST)
}

async function sendClaimEnquiry(
	firstname: string,
	surname: string,
	email: string,
	refund: string,
	ni: string,
	reference: string,
	postcode: string,
	address: string
): Promise<void> {
	const data = {
		FirstName: firstname,
		Surname: surname,
		Email: email,
		EstimatedClaim: refund,
		NI: ni,
		ClaimGenericReference: reference,
		Postcode: postcode,
		Address1: address,
	}
	await httpJsonRequest("/api/ui/claim/sendenquiry", data, RequestMethod.POST)
}

async function sendGeneralEnquiry(
	firstname: string,
	surname: string,
	email: string,
	reference: string,
	postcode: string,
	message: string
): Promise<void> {
	const data = {
		FirstName: firstname,
		Surname: surname,
		Email: email,
		ClaimReference: reference,
		Postcode: postcode,
		Message: message,
	}
	await httpJsonRequest("/api/ui/general/sendenquiry", data, RequestMethod.POST)
}

async function unsubscribe(email: string, type: "claimonly" | "all"): Promise<void> {
	await httpJsonRequest(`/api/ui/general/unsubscribe/${type}`, email, RequestMethod.POST)
}

async function cloneClaim(id: string): Promise<void> {
	await httpRequest(`/api/ui/claim/${id}/clone`, RequestMethod.GET)
}

async function reprintClaim(id: string): Promise<void> {
	await httpRequest(`/api/ui/claim/${id}/reprint`, RequestMethod.GET)
}
