import ButtonNavigation from "@/components/ButtonNavigation"
import RadioButtons from "@/components/RadioButtons"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const AreYouMarriedStep: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 my-2 text-center">
				<p class="small bold my-0">Are you currently married or in a civil partnership?</p>
				<RadioButtons
					name="married"
					value={App.CustomerClaim.Married}
					setValue={(value) => (App.CustomerClaim.Married = value)}
					data-validation="Please tell us whether or not you are currently married or in a civil partnership"
				/>
			</div>

			<ButtonNavigation />
		</>
	)
}

export default defineComponent(AreYouMarriedStep)
