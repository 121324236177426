import { unref } from "vue"
import type { Component } from "vue-utils"
import type { ClaimStepData } from "."
import ClaimSteps from "."
import AlreadyClaimed from "./issues/AlreadyClaimed"
import DetailsRejected from "./issues/DetailsRejected"
import EmployerDetailsNeeded from "./issues/EmployerDetailsNeeded"
import LackOfExpenses from "./issues/LackOfExpenses"
import MarriageClaimNotValid from "./issues/MarriageClaimNotValid"
import NoEmploymentHistory from "./issues/NoEmploymentHistory"
import NoTaxPaid from "./issues/NoTaxPaid"
import RequireSelfAssessment from "./issues/RequireSelfAssessment"
import UniformClaimed from "./issues/UniformClaimed"
import WaitUntilNextTaxYear from "./issues/WaitUntilNextTaxYear"
import AboutYouStep from "./mod/AboutYouStep"
import BasicInformationStep from "./mod/BasicInformationStep"
import DetailsPassedStep from "./mod/DetailsPassedStep"
import CompleteStep from "./standard/CompleteStep"
import ComplianceStatement from "./standard/ComplianceStatement"
import EmailStep from "./standard/EmailStep"
import EmployerStep from "./standard/EmployerStep"
import EstimateStep from "./standard/EstimateStep"
import IncomeStep from "./standard/IncomeStep"
import AreYouMarriedStep from "./standard/marriage/AreYouMarriedStep"
import MarriageClaimedBeforeStep from "./standard/marriage/MarriageClaimedBeforeStep"
import MarriageCompleteStep from "./standard/marriage/MarriageCompleteStep"
import MarriageDateStep from "./standard/marriage/MarriageDateStep"
import MarriageEarningsStep from "./standard/marriage/MarriageEarningsStep"
import MarriageNationalInsuranceStep from "./standard/marriage/MarriageNationalInsuranceStep"
import MarriageSignatureStep from "./standard/marriage/MarriageSignatureStep"
import SpouseEarningsCheckStep from "./standard/marriage/SpouseEarningsCheckStep"
import SpouseNameStep from "./standard/marriage/SpouseNameStep"
import NameStep from "./standard/NameStep"
import NationalInsuranceStep from "./standard/NationalInsuranceStep"
import OccupationStep from "./standard/OccupationStep"
import PandemicWorkFromHomeStep from "./standard/PandemicWorkFromHomeStep"
import PhoneNumberStep from "./standard/PhoneNumberStep"
import PostcodeStep from "./standard/PostcodeStep"
import QualifyingStep from "./standard/QualifyingStep"
import ReviewStep from "./standard/ReviewStep"
import SelfAssessedChecks from "./standard/SelfAssessedChecksStep"
import SelfAssessedStep from "./standard/SelfAssessedStep"
import SignatureStep from "./standard/SignatureStep"
import SubscriptionDetails from "./standard/SubscriptionDetailsStep"
import SubscriptionsStep from "./standard/SubscriptionsStep"
import TaxYearsStep from "./standard/TaxYearsStep"
import UniformTaxYearsStep from "./standard/UniformTaxYearsStep"
import WelcomeStep from "./standard/WelcomeStep"
import WorkFromHomeStep from "./standard/WorkFromHomeStep"

/*
   Step components are declared separately to the step information data so that the step components can reference other
   steps without causing errors from circular imports
*/

const stepComponents = new Map<ClaimStepData, Component>()

stepComponents.set(ClaimSteps.Welcome, WelcomeStep)
stepComponents.set(ClaimSteps.Employer, EmployerStep)
stepComponents.set(ClaimSteps.Occupations, OccupationStep)
stepComponents.set(ClaimSteps.Postcode, PostcodeStep)
stepComponents.set(ClaimSteps.ComplianceStatement, ComplianceStatement)
stepComponents.set(ClaimSteps.AreYouMarried, AreYouMarriedStep)
stepComponents.set(ClaimSteps.PandemicWorkFromHome, PandemicWorkFromHomeStep)
stepComponents.set(ClaimSteps.Income, IncomeStep)
stepComponents.set(ClaimSteps.TaxYears, TaxYearsStep)
stepComponents.set(ClaimSteps.Name, NameStep)
stepComponents.set(ClaimSteps.Email, EmailStep)
stepComponents.set(ClaimSteps.PhoneNumber, PhoneNumberStep)
stepComponents.set(ClaimSteps.Qualifying, QualifyingStep)
stepComponents.set(ClaimSteps.UniformTaxYears, UniformTaxYearsStep)
stepComponents.set(ClaimSteps.SelfAssessedStep, SelfAssessedStep)
stepComponents.set(ClaimSteps.SelfAssessedChecks, SelfAssessedChecks)
stepComponents.set(ClaimSteps.Estimate, EstimateStep)
stepComponents.set(ClaimSteps.SpouseEarningsCheck, SpouseEarningsCheckStep)
stepComponents.set(ClaimSteps.MarriagePreviousClaims, MarriageClaimedBeforeStep)
stepComponents.set(ClaimSteps.MarriageEarnings, MarriageEarningsStep)
stepComponents.set(ClaimSteps.SpouseName, SpouseNameStep)
stepComponents.set(ClaimSteps.MarriageDate, MarriageDateStep)
stepComponents.set(ClaimSteps.MarriageNationalInsurance, MarriageNationalInsuranceStep)
stepComponents.set(ClaimSteps.MarriageSignature, MarriageSignatureStep)
stepComponents.set(ClaimSteps.MarriageComplete, MarriageCompleteStep)
stepComponents.set(ClaimSteps.WorkFromHome, WorkFromHomeStep)
stepComponents.set(ClaimSteps.Subscriptions, SubscriptionsStep)
stepComponents.set(ClaimSteps.SubscriptionDetails, SubscriptionDetails)
stepComponents.set(ClaimSteps.NationalInsurance, NationalInsuranceStep)
stepComponents.set(ClaimSteps.ReviewClaim, ReviewStep)
stepComponents.set(ClaimSteps.Signature, SignatureStep)
stepComponents.set(ClaimSteps.ClaimComplete, CompleteStep)

stepComponents.set(ClaimSteps.BasicInformation, BasicInformationStep)
stepComponents.set(ClaimSteps.AboutYou, AboutYouStep)
stepComponents.set(ClaimSteps.DetailsPassed, DetailsPassedStep)

stepComponents.set(ClaimSteps.NoTaxPaid, NoTaxPaid)
stepComponents.set(ClaimSteps.AlreadyClaimed, AlreadyClaimed)
stepComponents.set(ClaimSteps.RequireSelfAssessment, RequireSelfAssessment)
stepComponents.set(ClaimSteps.NoEmploymentHistory, NoEmploymentHistory)
stepComponents.set(ClaimSteps.MarriageClaimNotValid, MarriageClaimNotValid)
stepComponents.set(ClaimSteps.LackOfExpenses, LackOfExpenses)
stepComponents.set(ClaimSteps.UniformClaimed, UniformClaimed)
stepComponents.set(ClaimSteps.EmployerDetailsNeeded, EmployerDetailsNeeded)
stepComponents.set(ClaimSteps.WaitUntilNextTaxYear, WaitUntilNextTaxYear)
stepComponents.set(ClaimSteps.DetailsRejected, DetailsRejected)

export const getStepComponent = (step: ClaimStepData) => stepComponents.get(unref(step))
