import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import SelectGender from "../components/SelectGender"
import SelectTitle from "../components/SelectTitle"

const SpouseNameStep: ReactiveComponent = () => {
	const missingGender = App.CustomerClaim.MarriageData?.Gender === null

	//If marriage data was set up later, the gender may have been skipped - important to get this information now
	function renderMissingOwnGender() {
		return (
			<>
				<div class="col-12 my-2 mt-4 claim-label bold">
					<i>Your</i> details
				</div>
				<div class="col-12 col-md-6">
					<SelectTitle title={App.CustomerClaim.Title} setTitle={(title) => (App.CustomerClaim.Title = title)} />
				</div>
				<div class="col-12 col-md-6 ">
					<SelectGender
						gender={App.CustomerClaim.MarriageData?.Gender ?? null}
						setGender={(gender) => {
							if (App.CustomerClaim.MarriageData) {
								App.CustomerClaim.MarriageData.Gender = gender
							}
						}}
					/>
				</div>
				<div class="mb-4 col-12" />
			</>
		)
	}

	return () => (
		<>
			{App.CustomerClaim.MarriageData !== null && (
				<>
					{missingGender && renderMissingOwnGender()}

					<div class="col-12 my-2 claim-label bold">
						Your <span class="bold">Spouse's</span> details
					</div>
					<div class="col-12 my-2 col-md-6">
						<SelectTitle
							title={App.CustomerClaim.MarriageData.PartnerTitle ?? ""}
							setTitle={(title) => {
								if (App.CustomerClaim.MarriageData !== null) {
									App.CustomerClaim.MarriageData.PartnerTitle = title
								}
							}}
						/>
					</div>
					<div class="col-12 my-2 col-md-6">
						<SelectGender
							gender={App.CustomerClaim.MarriageData.PartnerGender ?? null}
							setGender={(gender) => {
								if (App.CustomerClaim.MarriageData !== null) {
									App.CustomerClaim.MarriageData.PartnerGender = gender
								}
							}}
						/>
					</div>
					<div class="col-12 my-2 col-md-6">
						<input
							type="text"
							class="form-control"
							name="first_name_spouse"
							autocomplete="on"
							v-model={App.CustomerClaim.MarriageData.PartnerFirstName}
							placeholder="Your SPOUSE's first name(s)"
							required
						/>
					</div>
					<div class="col-12 my-2 col-md-6">
						<input
							type="text"
							class="form-control"
							name="second_name_spouse"
							autocomplete="on"
							v-model={App.CustomerClaim.MarriageData.PartnerLastName}
							placeholder="Your SPOUSE'S surname"
							required
						/>
					</div>
					<ButtonNavigation />
				</>
			)}
		</>
	)
}

export default defineComponent(SpouseNameStep)
