import ButtonNavigation from "@/components/ButtonNavigation"
import Question from "@/components/Question"
import RadioButtons from "@/components/RadioButtons"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const MarriageEarningsStep: ReactiveComponent = () => {
	const isHighEarner = computed(() => App.CustomerClaim.MarriageData?.HighestEarner === "me")

	const taxYearsSorted = computed(() => {
		const years = [...(App.CustomerClaim.MarriageData?.MarriageClaimTaxYears ?? [])]
		years.sort((y1, y2) => y2.Year - y1.Year)
		return years
	})
	return () => (
		<>
			<div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 mx-auto mb-4">
				<div class="my-3 claim-label bold">
					<strong>Your</strong> annual income
				</div>
				{isHighEarner.value && !!App.CustomerClaim.MarriageData && (
					<>
						<Question question="Did you earn more than £100,000 in any of the last 5 tax years?">
							<RadioButtons
								name="over-earner"
								value={App.CustomerClaim.MarriageData?.EarningsAboveMaximum}
								setValue={(value) => {
									if (App.CustomerClaim.MarriageData !== null) {
										App.CustomerClaim.MarriageData.EarningsAboveMaximum = value
									}
								}}
								required
								reverse
							/>
						</Question>
					</>
				)}
				{!(App.CustomerClaim.MarriageData?.EarningsAboveMaximum ?? true) && (
					<>
						<p>The tax year runs from 6th April to the 5th April. In the following tax years how much did you earn?</p>
						<div class="my-3">
							<ul class="list-group">
								{taxYearsSorted.value.map((taxYear, index) => (
									<li class="list-group-item bg-transparent border-0" key={taxYear.Year}>
										<div class="row">
											<div class="col-6 col-md-8">
												<strong>{index === 0 ? "Current year" : `Apr ${taxYear.Year}/${taxYear.Year + 1}`}</strong> -{" "}
												{isHighEarner.value ? taxYear.QualifyingRangeHighEarner : taxYear.QualifyingRangeLowEarner}
											</div>
											<div class="col-6 col-md-4 text-right">
												{isHighEarner.value && (
													<>
														<label class="bold mx-2">
															Yes&nbsp;
															<input
																type="radio"
																class="required"
																data-validation={`Please tell us about your earnings for ${taxYear.Year}/${taxYear.Year + 1}`}
																value={true}
																name={`rbTYH${taxYear.Year}`}
																v-model={taxYear.EarningsInRangeHighEarner}
															/>
														</label>
														<label class="bold">
															No&nbsp;
															<input
																type="radio"
																class="required"
																value={false}
																name={`rbTYH${taxYear.Year}`}
																v-model={taxYear.EarningsInRangeHighEarner}
															/>
														</label>
													</>
												)}
												{!isHighEarner.value && (
													<>
														<label class="bold mx-2">
															Less&nbsp;
															<input
																type="radio"
																class="required"
																data-validation={`Please tell us about your earnings for ${taxYear.Year}/${taxYear.Year + 1}`}
																value={true}
																name={`rbTYH${taxYear.Year}`}
																v-model={taxYear.EarningsInRangeLowEarner}
															/>
														</label>
														<label class="bold">
															More&nbsp;
															<input
																type="radio"
																class="required"
																value={false}
																name={`rbTYH${taxYear.Year}`}
																v-model={taxYear.EarningsInRangeLowEarner}
															/>
														</label>
													</>
												)}
											</div>
										</div>
									</li>
								))}
							</ul>
						</div>
					</>
				)}
			</div>
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(MarriageEarningsStep)
